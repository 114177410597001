import { NavLink } from "react-router-dom";
import { useNavMenu } from "../context/NavMenuContext";
import { MenuNavWrapper, List, ListItem } from "../styles/Components.style.js";

const NavMenu = () => {
  const { isOpen, setIsOpen } = useNavMenu();

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <MenuNavWrapper
      initial={{ x: "100%" }}
      animate={isOpen ? { x: 0 } : { x: "100%" }}
      transition={{ type: "spring", stiffness: 500, damping: 40 }}
    >
      <List>
        <ListItem>
          <NavLink to="/" className="nav-menu" onClick={handleLinkClick}>
            Home{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M13.5 4.5L21 12M21 12L13.5 19.5M21 12H3"
                stroke="#71795B"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink to="/safety" className="nav-menu" onClick={handleLinkClick}>
            Safety{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M13.5 4.5L21 12M21 12L13.5 19.5M21 12H3"
                stroke="#71795B"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink
            to="/activities"
            className="nav-menu"
            onClick={handleLinkClick}
          >
            Activities{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M13.5 4.5L21 12M21 12L13.5 19.5M21 12H3"
                stroke="#71795B"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink to="/hotels" className="nav-menu" onClick={handleLinkClick}>
            Hotel Info{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M13.5 4.5L21 12M21 12L13.5 19.5M21 12H3"
                stroke="#71795B"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </NavLink>
        </ListItem>
      </List>
    </MenuNavWrapper>
  );
};

export default NavMenu;
