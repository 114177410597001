import {
  PrecautionSwiper,
  PrecautionsWrapper,
  SafetyPageWrapper,
  SuppliesWrapper,
  SuppliesSwiper,
  SuppliesBox,
} from "../styles/safety.style";
import {
  FooterButtonsGrid,
  FooterButtonsWrapper,
  LargeText,
  SmallText,
} from "../styles/Home.style";

import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Header from "../components/Header";
import RouteCity from "../components/RouteCity";
import { NavLink } from "react-router-dom";

import { useEffect } from "react";

const Safety = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const Precautions = [
    {
      title: "No Driving After 8:00 PM",
      description:
        "Our latest arrival anywhere will be 8:00 PM. Every arrival except Louisville, Kentucky will be before 5:00 PM. That's only two arrivals after 5:00 PM!",
      icon: "clock",
    },
    {
      title: "Crime Rate of 7 or Lower",
      description:
        "We have strategically planned our route using ADT to go around areas with a violent crime rate of <7. To put this in perspective, Boulder has a violent crime rate of 4.",
      icon: "shield",
    },
    {
      title: "Friends & Family Nearby",
      description:
        "We have friends and family that live close enough for help along the route. This assures that if anything unexpected happens, we have a place to stay if need be.",
      icon: "family",
    },
    {
      title: "24/7 Location Sharing",
      description:
        "We will carry our phones along with two AirTags so that you are never uncertain about our location.",
      icon: "location",
    },
  ];

  const Supplies = [
    {
      title: "Extra Gas",
      description:
        "We will bring a medium sized tank of gas to prevent running out.",
      icon: "YourIconHere",
    },
    {
      title: "MREs",
      description:
        "We will bring a variety of MREs along with various other foods and drinks to prevent dying of starvation.",
      icon: "YourIconHere",
    },
    {
      title: "Portable Battery",
      description:
        "We will bring a portable battery to charge phones or anything else if needed.",
      icon: "YourIconHere",
    },
    {
      title: "Camping Supplies",
      description:
        "We will bring a variety of camping/hiking supplies. Including bear spray, bear containers, etc.",
      icon: "YourIconHere",
    },
    {
      title: "USAA Roadside",
      description:
        "We will make sure USAA roadside assistance is set up just in case of car obstacles.",
      icon: "YourIconHere",
    },
    {
      title: "Energy Drinks",
      description: "We will bring energy drinks in case fatigue sets in.",
      icon: "YourIconHere",
    },
  ];

  return (
    <SafetyPageWrapper>
      <Header />
      <PrecautionsWrapper>
        <LargeText isHeader={true}>Precautions</LargeText>
        <PrecautionSwiper>
          <Swiper
            className="safety-slider"
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={20}
            slidesPerView={1}
            loop={false}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
          >
            {Precautions.map((precaution, index) => (
              <SwiperSlide key={index}>
                <RouteCity
                  icon={precaution.icon}
                  cityName={precaution.title}
                  description={precaution.description}
                  last={true}
                  isSafety={true}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </PrecautionSwiper>
      </PrecautionsWrapper>
      <SuppliesWrapper>
        <LargeText isHeader={true}>Supplies</LargeText>
        <SuppliesSwiper>
          <Swiper
            className="supplies-slider"
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={10}
            slidesPerView={"auto"}
            loop={false}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
          >
            {Supplies.map((supply, index) => (
              <SwiperSlide key={index}>
                <SuppliesBox>
                  <LargeText isCityName={true}>{supply.title}</LargeText>
                  <SmallText>{supply.description}</SmallText>
                </SuppliesBox>
              </SwiperSlide>
            ))}
          </Swiper>
        </SuppliesSwiper>
      </SuppliesWrapper>
      <FooterButtonsWrapper>
        <LargeText isHeader={true}>Need More Details?</LargeText>
        <FooterButtonsGrid style={{ marginBottom: "30px" }}>
          <a href="/#route-container">Route Info</a>

          <NavLink className="footer-btn" to="/hotels">
            Hotel Info
          </NavLink>
          <NavLink className="footer-btn" to="/activities">
            Activities
          </NavLink>
        </FooterButtonsGrid>

        <svg
          className="footerImg"
          width="393"
          height="71"
          viewBox="0 0 393 71"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="-181"
            y="70.7271"
            width="100%"
            height="754.921"
            transform="rotate(-90 -181 70.7271)"
            fill="#2D2D34"
          />
          <line
            x1="-181"
            y1="34.335"
            x2="573.921"
            y2="34.335"
            stroke="white"
            stroke-width="3"
            stroke-dasharray="20 20"
          />
        </svg>
      </FooterButtonsWrapper>
    </SafetyPageWrapper>
  );
};

export default Safety;
