import { motion } from "framer-motion";
import { HeaderWrapper, MenuLines } from "../styles/Header.style";

import { useNavMenu } from "../context/NavMenuContext";
import NavMenu from "./NavMenu";
import { NavLink } from "react-router-dom";

const Header = () => {
  const { isOpen, setIsOpen } = useNavMenu();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <>
      <HeaderWrapper>
        <NavLink to="/" onClick={handleLinkClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="86"
            height="46"
            viewBox="0 0 86 46"
            fill="none"
          >
            <path
              d="M77.4 20.61L71.43 27.72L55.6 9.37L53.31 11.32L44.25 1L25.67 26.35L22.11 22.22L14.42 36.21L8.8 35.75L1 44.7C1 44.7 21.65 36.9 34.5 36.9H84.97L77.4 20.61ZM16.15 36.9L22.34 26.12L26.36 33.35L16.15 36.91V36.9ZM47 22.85L39.09 20.38L31.18 21.87L43.68 4.89L47.01 11.66L42.99 14.76L47.01 22.85H47ZM59.39 23.36L58.82 28.98L52.74 21.18L54.92 13.15L63.87 22.84L59.4 23.36H59.39Z"
              fill="#71795B"
              stroke="#71795B"
              strokeWidth="0.5"
              strokeMiterlimit="14.82"
              strokeLinecap="square"
            />
          </svg>
        </NavLink>

        <MenuLines onClick={toggleMenu}>
          <motion.svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="42"
            viewBox="0 0 42 42"
            fill="none"
          >
            <motion.path
              d="M6.5625 15.75H35.4375"
              stroke="black"
              strokeWidth="2.625"
              strokeLinecap="round"
              strokeLinejoin="round"
              animate={
                isOpen ? { rotate: 45, y: 6 } : { rotate: 0, x: 0, y: 0 }
              }
              initial={false}
              transformOrigin="center"
              transition={{ type: "spring", stiffness: 300, damping: 20 }}
            />
            <motion.path
              d="M6.5625 27.5625H35.4375"
              stroke="black"
              strokeWidth="2.625"
              strokeLinecap="round"
              strokeLinejoin="round"
              animate={
                isOpen ? { rotate: -45, y: -6 } : { rotate: 0, x: 0, y: 0 }
              }
              initial={false}
              transformOrigin="center"
              transition={{ type: "spring", stiffness: 300, damping: 20 }}
            />
          </motion.svg>
        </MenuLines>
      </HeaderWrapper>
      {isOpen && <NavMenu />}
    </>
  );
};

export default Header;
