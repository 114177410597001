import styled from "styled-components";
import { motion } from "framer-motion";
import { mediaQueries } from "./mediaQueries";
import theme from "./theme";

export const MenuNavWrapper = styled(motion.div)`
  display: none;

  ${mediaQueries("xs")`
  display: flex;
  position: fixed;
  top: 85px;
  right: 0;
  width: 100vw;
  height: 100%;
  background: white;
  z-index: 1000; /* Ensure this is higher than any other z-index on the page */
  
  
  
  `}
`;

export const List = styled.ul`
  list-style-type: none; /* Remove bullet points */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
  margin-top: 50px;
`;

export const ListItem = styled.li`
  margin: 25px 40px; /* Add some space between items */

  .nav-menu {
    text-decoration: none;
    color: #000;
    font-family: ${theme.font.Mundial};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    align-items: center;
    display: flex;
    gap: 8px;
  }
`;

export const CityActivityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${theme.font.Mundial};

  gap: 21px;
  margin-bottom: 50px;
`;

export const LocationNameAndImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;

  img {
    width: 323px;
    height: 175px;
    border-radius: 5px;
  }

  .city-header {
    font-size: 28px;
  }
`;

export const ActivitiesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .activityHeader {
    text-align: start;
    font-size: 24px;
    font-weight: 600;
    margin-left: 36px;
  }
`;

export const ActivitiesSwiper = styled.div`
  display: flex;
  .swiper-slide {
    margin-top: 4px;
    width: 307px;
  }

  .swiper {
    width: 100%;
    height: 260px;
    padding-left: 36px;
  }

  .swiper-pagination-bullet-active {
    background: ${theme.color.primarydark};
  }
`;

export const ActivityBox = styled.div`
  border-radius: 5px;
  background: #fff;

  /* Shadow */
  // box-shadow: 0px 2px 5px -1px rgba(50, 50, 93, 0.25),
  //   0px 1px 3px -1px rgba(0, 0, 0, 0.3);
  width: 323px;
  height: 249px;
  padding: 16px;

  justify-content: center;
  align-items: center;
  text-align: center;

  .activity-name {
    font-size: 22px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 20px;
    margin-top: 6px;
  }

  img {
    border-radius: 5px;
    width: 255.426px;
    height: 146.187px;
  }
`;

export const ActivityPageLayout = styled.div`
  margin: 50px 0px;
  height: 1500px;
`;
