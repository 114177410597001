import styled from "styled-components";
import theme from "./theme";

export const SafetyPageWrapper = styled.div``;

export const PrecautionsWrapper = styled.div``;

export const SuppliesWrapper = styled.div``;

export const PrecautionSwiper = styled.div`
  .safety-slider {
    position: relative;

    height: 250px;
    position: relative;
  }

  .swiper-pagination-bullets {
    left: 120px;
  }

  .swiper-pagination {
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: 100%;
    text-align: center;
  }

  .swiper-wrapper {
    margin-left: 60px;
    margin-top: 3px;
  }

  .swiper-pagination-bullet-active {
    background-color: ${theme.color.primarydark};
  }
`;

export const SuppliesBox = styled.div`
  width: 268px;
  height: auto;

  border-radius: 5px;
  background: #fff;

  /* Shadow */
  box-shadow: 0px 2px 5px -1px rgba(50, 50, 93, 0.25),
    0px 1px 3px -1px rgba(0, 0, 0, 0.3);

  padding: 10px 15px;
`;
export const SuppliesSwiper = styled.div`
  .supplies-slider {
    height: 190px;
  }

  .swiper-pagination-bullet-active {
    background-color: ${theme.color.primarydark};
  }

  .swiper-wrapper {
    margin-left: 60px;
    margin-top: 3px;
  }
`;
