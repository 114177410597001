// src/styles/GlobalStyles.js
import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  html, body {
    margin: 0;
    padding: 0;
    
      max-width: 100%;
      overflow-x: hidden;
      height: 100%;
  
  }


  body{
    overflow-x: hidden;
  }

  #root {
    width: 100%;
    height: 100%;
  }

  body {
    background-color: #fff; /* Change to your desired background color */
  }

  /* Additional global styles can go here */
`;

export default GlobalStyles;
