export const breakpoints = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
  xxxl: 2500,
};

export const mediaQueries = (key) => (style) =>
  `@media (max-width: ${breakpoints[key]}px) { ${style} }`;
