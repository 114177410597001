import { NotMobileLg, NotMobileWarning } from "../styles/Home.style";

const NotMobile = () => {
  return (
    <NotMobileWarning style={{ marginTop: "300px" }}>
      <NotMobileLg>
        Look at you trying to see operation dabble on a large screen. No can do,
        go back to mobile, silly goose.
      </NotMobileLg>
    </NotMobileWarning>
  );
};

export default NotMobile;
