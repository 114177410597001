export default {
  color: {
    primaryLight: "#9DAD6F",
    primarydark: "#71795B",
    darkgrey: "#676D73",
    lightgrey2: "#979797",
    bordercolor: "#E9E9E9",
    black: "#2D2D34",
    white: "#fff",
  },
  font: {
    Mundial: "Mundial",
  },
};
