import styled, { css } from "styled-components";
import { mediaQueries } from "./mediaQueries";
import theme from "./theme";
import { motion } from "framer-motion";

export const PageLayout = styled.div`
  width: 100%;
  margin: auto;

  align-items: center;
  position: relative;

  &:before {
    content: ;
  }
`;

export const MobilePageLayout = styled.div`
  display: none;

  ${mediaQueries("xs")`
  
  display: flex;
  flex-direction: column;
  padding: 0px 25px;


  .footerImg{
    position: absolute;
    bottom: -1px;
    left: 0;
  }


  
  
  
  `}
`;

export const NotMobileWarning = styled.div`
  display: flex;
  width: 400px;
  margin: 0 auto;
  text-align: center;

  ${mediaQueries("xs")`
display: none;



`}
`;

export const NotMobileLg = styled.h1`
  font-size: 20px;
  font-family: ${theme.font.Mundial};
  font-weight: normal;
`;

export const LargeText = styled.h1`
  color: ${theme.color.black};
  font-family: ${theme.font.Mundial};
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  ${(props) =>
    props.isHeader &&
    css`
      font-size: 32px;
      text-align: center;
      margin-bottom: 30px;
    `};

  ${(props) =>
    props.isCityName &&
    css`
      color: var(--Main-Dark, #2d2d34);
      font-family: Mundial;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-top: 17px;
    `};
`;
export const SmallText = styled.h2`
  color: ${theme.color.darkgrey};
  font-family: ${theme.font.Mundial};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 16px;
  ${(props) =>
    props.isDuration &&
    css`
      color: ${theme.color.primarydark};
    `};

  ${(props) =>
    props.isCitySmall &&
    css`
      color: var(--Light-Grey, #6a6a6f);
      font-family: Mundial;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 9px;

      span {
        color: #9dad6f;
      }

      ${(props) =>
        props.isArrival &&
        css`
          margin-bottom: 0px;
        `};

      ${(props) =>
        props.isSafety &&
        css`
          line-height: 16px;
        `};
    `};
`;

export const Button = styled.button`
  width: 151px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 5px;
  background-color: ${theme.color.primarydark};
  font-family: ${theme.font.Mundial};
  border: none;
  color: white;
  font-weight: 400;
  font-size: 15px;
  ${(props) =>
    props.isOutlined &&
    css`
      border: solid ${theme.color.primarydark} 2px;
      background-color: white;
      color: ${theme.color.primarydark};
    `};
`;

export const BGElement = styled.div`
  position: absolute;
  ${(props) =>
    props.isTopCorn &&
    css`
      top: 0;
      left: 0;
    `};
`;
export const HeroContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  justify-content: center;
  align-items: center;

  a {
    width: 151px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 5px;
    background-color: ${theme.color.primarydark};
    font-family: ${theme.font.Mundial};
    border: none;
    color: white;
    font-weight: 400;
    font-size: 15px;
    text-decoration: none;
    justify-content: center;
    display: inline-flex;
    align-items: center;
  }

  .hero-btn {
    width: 151px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 5px;
    background-color: ${theme.color.primarydark};
    font-family: ${theme.font.Mundial};
    border: none;
    color: white;
    font-weight: 400;
    font-size: 15px;
    text-decoration: none;
    justify-content: center;
    display: inline-flex;
    align-items: center;
    border: solid ${theme.color.primarydark} 2px;
    background-color: white;
    color: ${theme.color.primarydark};
  }
`;

export const ButtonWrapperHead = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
  margin-top: 23px;
`;

export const HeroTextWrapper = styled.div`
  padding: 0 10px;
  span {
    color: ${theme.color.primarydark};
  }
`;

export const ImageContainer = styled.div`
  margin-top: 40px;

  img {
    height: 350px;
  }
`;

export const RouteContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  justify-content: center;
  align-items: center;
  min-height: 1000px;
`;

export const RouteCityWrapper = styled(motion.div)`
  width: 268px;
  max-height: 270px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px 0px;
  gap: 4px;

  ${(props) =>
    props.isSafety &&
    css`
      width: 300px;
    `};
`;

export const RouteCityBox = styled(motion.div)`
  width: 268px;
  max-height: 187px;
  border-radius: 5px;
  display: block;
  // background: #fff;

  /* Shadow */
  box-shadow: 0px 2px 5px -1px rgba(50, 50, 93, 0.25),
    0px 1px 3px -1px rgba(0, 0, 0, 0.3);

  padding: 12px 20px 14px 14px;

  ${(props) =>
    props.isSafety &&
    css`
      width: 320px;
      min-height: 200px;
    `};
`;

export const RouteCityIcon = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  position: relative;
  margin-left: -5px;
`;

export const MoreInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 9px;
  gap: 5px;

  .more-info-btn {
    color: var(--Light-Grey, #6a6a6f);
    font-family: Mundial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
  }
`;

export const MoreInfoIcon = styled(motion.svg)`
  width: 11px;
  height: 10px;
  border-radius: 50%;
`;

export const LineWithDistance = styled(motion.div)`
  display: flex;
  align-items: center;
`;

export const RouteLine = styled(motion.div)`
  margin: 5px 0px;
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const FooterButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`;

export const FooterButtonsGrid = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 48px);
  grid-template-columns: repeat(2, 151px);
  gap: 20px;
  margin-bottom: 100px;

  a {
    width: 151px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 5px;
    background-color: ${theme.color.primarydark};
    font-family: ${theme.font.Mundial};
    border: none;
    color: white;
    font-weight: 400;
    font-size: 15px;
    text-decoration: none;
    justify-content: center;
    display: inline-flex;
    align-items: center;
  }

  .footer-btn {
    width: 151px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 5px;
    background-color: ${theme.color.primarydark};
    font-family: ${theme.font.Mundial};
    border: none;
    color: white;
    font-weight: 400;
    font-size: 15px;
    text-decoration: none;
    justify-content: center;
    display: inline-flex;
    align-items: center;
  }
`;
