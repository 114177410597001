import { mediaQueries } from "./mediaQueries";
import theme from "./theme";
import styled from "styled-components";
import { motion } from "framer-motion";

export const HeaderWrapper = styled.div`
  display: none;

  ${mediaQueries("xs")` width: 330px;
  margin: 40px auto 0px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  

  `}
`;

export const MenuLines = styled(motion.div)`
  width: 50px;
  height: 50px;
`;
