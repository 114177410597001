// src/pages/Home.js
import {
  ButtonWrapperHead,
  HeroContainer,
  HeroTextWrapper,
  LargeText,
  PageLayout,
  SmallText,
  MobilePageLayout,
  ImageContainer,
  RouteContainer,
  FooterButtonsWrapper,
  FooterButtonsGrid,
} from "../styles/Home.style";
import NotMobile from "../components/NotMobile";
import Header from "../components/Header";
import RouteCity from "../components/RouteCity";
import NavMenu from "../components/NavMenu";
import { useNavMenu } from "../context/NavMenuContext"; // Import the custom hook

import { NavLink } from "react-router-dom";

const Home = () => {
  const RouteCities = [
    {
      icon: "VB",
      cityName: "BWI",
      distance: "1664 miles",
      duration: "3 hr 30 mins",
      arrival: null,
      departure: "July 31st",
    },

    {
      icon: "CO",
      cityName: "Denver, Colorado",
      description: "",
      distance: "362 miles",
      duration: "5 hr 30 min",
      arrival: null,
      departure: "August 1st - 9:00 AM",
    },
    {
      icon: "CO",
      cityName: "Telluride, Colorado",
      description: "",
      distance: "274 miles",
      duration: "5 hr 7 min",
      arrival: "2:30 PM",
      departure: "August 5th - 10:00 AM",
    },
    {
      icon: "CO",
      cityName: "Sand Dune National Park",
      description: "",
      distance: "246 miles",
      duration: "3 hr 45 min",
      arrival: "3:00 PM",
      departure: "August 6th - 3:00 PM",
    },
    {
      icon: "CO",
      cityName: "DEN",
      description: "",
      distance: "1664 miles",
      duration: "4 hr 10 min",
      arrival: "6:15 PM",
      departure: "August 6th - nightime",
    },
    {
      icon: "VB",
      cityName: "BWI",
      description: "",

      arrival: null,
      departure: null,
      last: true,
    },
  ];

  const { isOpen } = useNavMenu(); // Access the context value

  console.log("ISOPEN.....", isOpen);

  return (
    <>
      <PageLayout>
        <Header />
        <NavMenu />
        <NotMobile />

        <MobilePageLayout>
          {" "}
          <HeroContainer>
            <HeroTextWrapper>
              <LargeText>
                Operation <span>Dabble</span>
              </LargeText>
              <SmallText>
                An amazing trip to the Rockies by Jamison and Ryan.
              </SmallText>
            </HeroTextWrapper>
            <ButtonWrapperHead>
              <a href="#route-container">See Our Route</a>
              <NavLink className="hero-btn" to="/safety">
                Safety Info
              </NavLink>
            </ButtonWrapperHead>
            <ImageContainer>
              <img src="../assets/image.png" alt="Yosemite"></img>
            </ImageContainer>
          </HeroContainer>
          <section id="route-section">
            <RouteContainer id="route-container">
              <LargeText isHeader={true}>The Route</LargeText>
              {RouteCities.map((city, index) => {
                return (
                  <RouteCity
                    key={index}
                    icon={city.icon}
                    cityName={city.cityName}
                    description={city.description}
                    distance={city.distance}
                    duration={city.duration}
                    arrival={city.arrival}
                    departure={city.departure}
                    last={city.last}
                  />
                );
              })}
            </RouteContainer>
          </section>
          <FooterButtonsWrapper>
            <LargeText isHeader={true}>Need More Details?</LargeText>
            <FooterButtonsGrid>
              <a href="#route-container">Route Info</a>

              <a className="footer-btn" href="/safety">
                Safety Info
              </a>
              <NavLink className="footer-btn" to="/hotels">
                Hotel Info
              </NavLink>
              <NavLink className="footer-btn" to="/activities">
                Activities
              </NavLink>
            </FooterButtonsGrid>

            <svg
              className="footerImg"
              width="393"
              height="71"
              viewBox="0 0 393 71"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="-181"
                y="70.7271"
                width="100%"
                height="754.921"
                transform="rotate(-90 -181 70.7271)"
                fill="#2D2D34"
              />
              <line
                x1="-181"
                y1="34.335"
                x2="573.921"
                y2="34.335"
                stroke="white"
                stroke-width="3"
                stroke-dasharray="20 20"
              />
            </svg>
          </FooterButtonsWrapper>
        </MobilePageLayout>

        <NavMenu />
      </PageLayout>
    </>
  );
};

export default Home;
