import React from "react";
import {
  CityActivityWrapper,
  LocationNameAndImg,
  ActivitiesSwiper,
  ActivitiesWrapper,
  ActivityBox,
} from "../styles/Components.style";
import { LargeText } from "../styles/Home.style";
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const CityActivity = (props) => {
  const { cityName, img, activities } = props;

  const getImg = (img) => {
    return img === "dune"
      ? "../assets/dune.png"
      : img === "tell"
      ? "../assets/tell.png"
      : "../assets/grand.png";
  };

  const getActivityImg = (activityImg) => {
    return `../assets/${activityImg}.png`;
  };

  return (
    <CityActivityWrapper>
      <LocationNameAndImg>
        <LargeText className="city-header">{cityName}</LargeText>
        <img src={getImg(img)} alt={`${cityName} scenic view`} />
      </LocationNameAndImg>
      <div>
        <ActivitiesWrapper>
          {/* <LargeText className="activityHeader">Activities</LargeText> */}
          <ActivitiesSwiper>
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={50}
              slidesPerView={1}
              pagination={true}
            >
              {activities.map((activity, index) => (
                <SwiperSlide key={index}>
                  <ActivityBox>
                    <LargeText className="activity-name">
                      {activity.name}
                    </LargeText>
                    <img
                      src={getActivityImg(activity.img)}
                      alt={`${activity.name}`}
                    />
                  </ActivityBox>
                </SwiperSlide>
              ))}
            </Swiper>
          </ActivitiesSwiper>
        </ActivitiesWrapper>
      </div>
    </CityActivityWrapper>
  );
};

export default CityActivity;
