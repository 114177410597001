import { NavLink } from "react-router-dom";
import { Button, LargeText } from "../styles/Home.style";
import Header from "../components/Header";

const Hotels = () => {
  return (
    <>
      <Header />
      <LargeText
        style={{
          margin: "50px",
          fontSize: "25px",
          marginBottom: "30px",
          textAlign: "center",
        }}
      >
        Still planning this, Look at this picture instead.
      </LargeText>
      <img src="/assets/404img.jpg" alt="yosemite" />

      <Button
        style={{
          margin: "20px 120px",
        }}
      >
        <NavLink
          style={{
            textDecoration: "none",
            color: "white",
            margin: "20px auto 0 auto",
          }}
          to="/"
        >
          Back To Home
        </NavLink>
      </Button>
    </>
  );
};

export default Hotels;
