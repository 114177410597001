// src/context/NavMenuContext.js
import React, { createContext, useContext, useState } from "react";

const NavMenuContext = createContext();

export const useNavMenu = () => useContext(NavMenuContext);

export const NavMenuProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <NavMenuContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </NavMenuContext.Provider>
  );
};
