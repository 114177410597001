// src/App.js
import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Safety from "./pages/Safety";
import { NavMenuProvider } from "./context/NavMenuContext"; // Import the context provider
import Hotels from "./pages/Hotels";

import Activities from "./pages/Activities";

function App() {
  return (
    <NavMenuProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="safety" element={<Safety />} />
          <Route path="hotels" element={<Hotels />} />
          <Route path="activities" element={<Activities />} />
        </Routes>
      </BrowserRouter>
    </NavMenuProvider>
  );
}

export default App;
