import { NavLink } from "react-router-dom";
import {
  FooterButtonsGrid,
  FooterButtonsWrapper,
  LargeText,
} from "../styles/Home.style";
import Header from "../components/Header";
import { useEffect } from "react";

import CityActivity from "../components/CityActivity.js";
import { ActivityPageLayout } from "../styles/Components.style.js";

const Places = [
  {
    cityName: "Sand Dune National Park",
    activities: [
      { name: "Sandboarding", img: "sandboard" },
      { name: "Stars", img: "star" },
      { name: "Hiking", img: "waterfall" },
    ],
    img: "dune", // Path to the main image
  },
  {
    cityName: "Telluride",
    activities: [
      { name: "Gondola", img: "gondola" },
      { name: "Hiking", img: "tellhiking" },
      { name: "Via Ferrata", img: "viafarreta" },
      { name: "San Juan Road", img: "sanjuanroad" },
    ],
    img: "tell", // Path to the main image
  },
];

const Activities = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log("TEST");

  return (
    <>
      <Header />
      <ActivityPageLayout>
        {Places.map((place, index) => (
          <CityActivity
            key={index}
            cityName={place.cityName}
            img={place.img}
            activities={place.activities}
          />
        ))}
        <FooterButtonsWrapper>
          <LargeText isHeader={true}>Need More Details?</LargeText>
          <FooterButtonsGrid>
            <a href="#route-container">Route Info</a>

            <a className="footer-btn" href="/safety">
              Safety Info
            </a>
            <NavLink className="footer-btn" to="/hotels">
              Hotel Info
            </NavLink>
            <NavLink className="footer-btn" to="/activities">
              Activities
            </NavLink>
          </FooterButtonsGrid>

          <svg
            className="footerImg"
            width="393"
            height="71"
            viewBox="0 0 393 71"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="-181"
              y="70.7271"
              width="100%"
              height="754.921"
              transform="rotate(-90 -181 70.7271)"
              fill="#2D2D34"
            />
            <line
              x1="-181"
              y1="34.335"
              x2="573.921"
              y2="34.335"
              stroke="white"
              stroke-width="3"
              stroke-dasharray="20 20"
            />
          </svg>
        </FooterButtonsWrapper>
      </ActivityPageLayout>
    </>
  );
};

export default Activities;
